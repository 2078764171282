import { forwardRef } from 'react';
import './superContactsList.css'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import TableDropdown from '../tableDropdown/tableDropdown';


const SuperContactsList = forwardRef((props, ref) => {

    return (
        <div className='container-fluid h-100 super-contacts-list'>
            {
                props.listContactDetails?.map((val, idx) => {
                    return (
                        <>
                            <div className='row super-contacts-row' id={`row-${val.list_name}`} >
                                <div className='col d-flex flex-row justify-content-start align-items-center'>
                                    <button type='button' id={val.list_name} className='btn btn-light' onClick={(e)=>props.handleExpandClick(e)}>
                                        {
                                            props.current === val.list_name ? 
                                            <ExpandLessIcon className='svg-icons'/>
                                            :
                                            <ExpandMoreIcon className='svg-icons' />
                                        }
                                    </button>
                                    <div className='super-contacts-content'>
                                        <div>List Name  : <strong>{val.list_name}</strong></div>
                                        <div className='text-muted'>Total Contacts : {val.list_contact_count}</div>
                                    </div>
                                    {
                                        props.current === val.list_name ?  
                                        <TableDropdown hiddenSerialNos={props.hiddenSerialNos} handleHideSerialNos={props.handleHideSerialNos} handleSendEmail={props.handleSendEmail} handleCall={props.handleCall}/>
                                        :
                                        null
                                    }
                                   
                                </div>
                            </div>
                            <div className='row'>
                                {
                                    props.current === val.list_name ? <div className='col'>{props.children}</div>
                                    : 
                                    null
                                }
                            </div>
                        </>
                    )
                })
            }
        </div>
    )

})

export default SuperContactsList