// FacebookLoginButton.js
import React, { useEffect } from "react";

const FacebookLoginButton = () => {
  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "3604338063216196",
        cookie: true,
        xfbml: true,
        version: "v18.0",
      });
    };

    document.getElementById("loginButton").addEventListener("click", () => {
      launchWhatsAppSignup();
    });

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

    function launchWhatsAppSignup() {
      // Ensure fbq is defined before using it
      if (window.fbq) {
        window.fbq("trackCustom", "WhatsAppOnboardingStart", {
          appId: "3604338063216196",
          feature: "whatsapp_embedded_signup",
        });
      }

      window.FB.login(
        function (response) {
          if (response.authResponse) {
            const code = response.authResponse.code;
          } else {
            console.log("User cancelled login or did not fully authorize.");
          }
        },
        {
          config_id: "356797933756896",
          response_type: "code",
          override_default_response_type: true,
          extras: {},
        }
      );
    }
  }, []);

  return (
    <button
      id="loginButton"
      style={{
        backgroundColor: "#1877f2",
        border: 0,
        borderRadius: "4px",
        color: "#fff",
        cursor: "pointer",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontSize: "16px",
        fontWeight: "bold",
        height: "40px",
        padding: "0 24px",
      }}
    >
      Login with Facebook
    </button>
  );
};

export default FacebookLoginButton;
