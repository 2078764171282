import { ToastContainer,toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import setData from './utils';
import { useState } from 'react';
import { useEffect } from 'react';
import './DateSelector.css'
import { handleAuthError } from '../../../utils/errorHandlers';

const DateSelector = (props) =>{

    const [startDate,setStartDate] = useState("")
    const [endDate,setEndDate] = useState("")
    const [isPartOfInsights, setIsPartOfInsights] = useState(false)
  
    const handleDateChange = (e) =>{
      e.preventDefault()
      console.log(e.target.name)
      switch(e.target.name) {
        case "startDate" : setStartDate(e.target.value) ; break;
        case "endDate" : setEndDate(e.target.value) ; break;
      }
    }
  
    useEffect(()=>{
      setIsPartOfInsights(props.isPartOfInsights)
    },[])
  
    const handleGetInsights = async (e) => {
      if(startDate != '' && endDate!='') {
        if((new Date(startDate))>=(new Date(endDate))) {
          toast.warn("Please select a start date earlier than the end date.", {autoClose:"1500"})
        } else {
  
          const startDateObject = new Date(startDate)
          const endDateObject = new Date(endDate)
          const todayDateObject = new Date()
          if((endDateObject-startDateObject)/(1000*24*60*60)>31 || (Math.abs(todayDateObject-startDateObject))/(1000*24*60*60)>180) {
            toast.warn("Please select a end date within 31 days of start date. Max start date can be 6 months old.")
            return
          }
  
          const id = toast.loading("Fetching insights")
          const newParams = {...props,startDate :startDate, endDate:endDate}
          try {
            await setData(newParams)
            toast.update(id,{render : "Setting up graphs ...",type:"success",isLoading:false,autoClose:"500"})
          } catch(err) {
            console.log(err)
            toast.update(id,{render : "Opps ! Error occured",type:"error",isLoading:false,autoClose:"500"})
            handleAuthError(err)
          }
  
        }
      }
  
    }
  
    return (
      <div className={!isPartOfInsights?'component-date-selector': 'component-date-selector-with-insights'}>
        <div>
          <label>Start Date : </label>
          <input type="date" name="startDate" onChange={(e)=>handleDateChange(e)}></input>
        </div>
        <div>
          <label>End Date : </label>
          <input type="date" name="endDate" onChange={(e)=>handleDateChange(e)}></input>
        </div>
        <div style={{display:"flex",justifyContent:"flex-end",margin:"15px"}}>
          <Button onClick={(e)=>handleGetInsights(e)} style={{backgroundColor : "#202453", border:"0.5px solid white"}}>Get Insights</Button>
        </div>
      </div>
    )
  }

export default DateSelector