import React, { useState } from "react";
import './confirmUser.css'
import { Button } from "react-bootstrap";
import apiURL from "../../apiURL";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router";
import axios from "axios";

const ConfirmUser = () => {

    const navigate = useNavigate()

    const handleSubmit = (e) =>{
        e.preventDefault()
        axios.get(apiURL+`/api/auth/check_user/${e.target.email.value}`,{withCredentials:true})
            .then((response)=>{
                toast.success("User confirmed")
                setTimeout(()=>{
                    navigate('/reset_password')
                },1000)
            })
            .catch((err)=>{
                toast.warn(err.response.data.message)
            })
    }
    

    return (
        <div className="cu-container">
            <ToastContainer></ToastContainer>
            <div className="cu-form-container">
                <div className="cu-form-header">
                    <h4>Confirm Email Id</h4>
                </div>
                <form className="cu-form" onSubmit={(e)=>handleSubmit(e)} >
                    <label className="cu-label">Enter email to confirm password reset</label>
                    <input className="cu-email" type="email" placeholder="johndoe@example.com" name="email"></input>
                    <Button type="submit" >Confirm</Button>
                </form>
            </div>
        </div>
    )

}

export default ConfirmUser