// // PieChartComponent.js
// import React from "react";
// import { PieChart } from "react-minimal-pie-chart";

// const PieChartComponent = () => {
//   const data = [
//     { title: "Slice 1", value: 25, color: "#E38627" },
//     { title: "Slice 2", value: 50, color: "#C13C37" },
//     { title: "Slice 3", value: 25, color: "#6A2135" },
//   ];

//   return (
//     <div
//       className="d-flex justify-content-center align-items-center mt-4 "
//       style={{ height: "100%" , backgroundColor: ""}}
//     >
//       <PieChart data={data} style={{ width: "400px", height: "400px" }} />
//     </div>
//   );
// };

// export default PieChartComponent;


// PieChartComponent.js
// import React, { useEffect, useState } from "react";
// import { PieChart } from "react-minimal-pie-chart";

// const PieChartComponent = () => {
//   const [mailData, setMailData] = useState(null);

//   useEffect(() => {
//     // Replace 'API_ENDPOINT' with your actual API endpoint
//     fetch("http://13.201.105.72/get_mail_status")
//       .then((response) => response.json())
//       .then((data) => {
//         setMailData(data);
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   }, []);

//   const chartData = mailData
//     ? [
//         { title: "Total Mails Sent", value: mailData.totalMailsSent, color: "#3498db" },
//         { title: "Unsubscribed Emails", value: mailData.unsubscribedEmails, color: "#e74c3c" },
//         { title: "Other Undelivered Mails", value: mailData.otherUndeliveredMails, color: "#f39c12" },
//         { title: "Complaint List Mails", value: mailData.complaintListMails, color: "#2ecc71" }
//       ]
//     : [];

//   return (
//     <div className="d-flex justify-content-center align-items-center mt-4">
//       <PieChart data={chartData} style={{ width: "400px", height: "400px" }} />
//     </div>
//   );
// };

// export default PieChartComponent;

import React from "react";
import { PieChart } from "react-minimal-pie-chart";

const PieChartComponent = ({ data }) => {
  const chartData = data
    ? [
        {
          title: "Total Mails Sent",
          value: data.email_sent || 0,
          color: "rgb(203, 187, 87)",
        },
        {
          title: "Unsubscribed Emails",
          value: data.unsubscribed_mail || 0,
          color: "rgb(62, 62, 81)",
        },
        {
          title: "Other Undelivered Mails",
          value: data.other_undelivered_mails || 0,
          color: "rgb(161, 161, 161)",
        },
        {
          title: "Complaint List Mails",
          value: data.complaint_list_mails || 0,
          color: "rgb(118, 93, 126)",
        },
      ]
    : [];
  const legendWidth = 110; // Adjust the width of the legend area

  return (
    // <div className="d-flex justify-content-center align-items-center mt-4">
    //   <PieChart data={chartData} style={{ width: "400px", height: "400px" }} />
    // </div>
    //  <div className="d-flex justify-content-center align-items-center mt-4">
    //   <div style={{ marginRight: "20px" }}>
    //     {/* Legend Component */}
    //     <ul>
    //       {chartData.map((entry, index) => (
    //         <li key={index} style={{ color: entry.color }}>
    //           {entry.title}
    //         </li>
    //       ))}
    //     </ul>
    //   </div>
    //   <div>
    //     {/* Pie Chart Component */}
    //     <PieChart
    //       data={chartData}
    //       style={{ width: "400px", height: "400px" }}
    //       label={({ dataEntry }) => `${dataEntry.title}: ${dataEntry.value}`}
    //       labelStyle={(index) => ({
    //         fontSize: "12px",
    //         fontFamily: "sans-serif",
    //       })}
    //       animate
    //       lineWidth={60}
    //       labelPosition={112}
    //       startAngle={270}
    //     />
    //   </div>
    // </div>

    //  <div className="d-flex justify-content-center align-items-center mt-4">
    //   <PieChart
    //     data={chartData}
    //     style={{ width: "400px", height: "400px" }}
    //     label={() => null} // Remove the value labels
    //     labelStyle={(index) => ({
    //       fontSize: "12px",
    //       fontFamily: "sans-serif",
    //     })}
    //     animate
    //     lineWidth={60}
    //     labelPosition={112}
    //     startAngle={270}
    //   >
    //     {/* Custom Legend Items */}
    //     {chartData.map((entry, index) => (
    //       <g key={index} transform={`translate(${300},${index * 20})`}>
    //         <rect width="15" height="15" fill={entry.color} />
    //         <text x="20" y="12" fontSize="12px">{entry.title}</text>
    //         <text x="20" y="28" fontSize="10px" fill="#555">{entry.description}</text>
    //       </g>
    //     ))}
    //   </PieChart>
    //   </div>
    <div className="d-flex justify-content-center align-items-center p-3 mt-4">
      <div style={{}}>
        {/* Pie Chart Component */}
        <PieChart
          data={chartData}
          style={{ width: "400px", height: "400px" }}
          label={() => null} // Remove the value labels
          labelStyle={(index) => ({
            fontSize: "12px",
            fontFamily: "sans-serif",
          })}
          animate
          lineWidth={60}
          labelPosition={112}
          startAngle={270}
        />
      </div>
      <div
        className=""
        style={{
          // position: "relative",
          // width: `${legendWidth}px`,
          // backgroundColor: "Red",
          marginLeft: "20px",
        }}
      >
        {/* Custom Legend Items */}
        {chartData.map((entry, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "8px",
            }}
          >
            <div
              style={{
                width: "15px",
                height: "15px",
                backgroundColor: entry.color,
                marginRight: "8px",
              }}
            ></div>
            <div>
              <div style={{ fontSize: "12px", fontWeight: "bold" }}>
                {entry.title}
              </div>
              <div style={{ fontSize: "10px", color: "#555" }}>
                {entry.description}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PieChartComponent;
