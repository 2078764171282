import { useEffect, useState } from 'react'
import './sfOps.css'
import { fetchImportedSobjectNames, fetchListNames } from '../../../../utils/fetchers'
import { ToastContainer, toast } from 'react-toastify'
import SearchIcon from '@mui/icons-material/Search';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import axios from 'axios';
import apiURL from '../../../../apiURL';
import Popover from '../../../../components/popover/popover';
import { useNavigate } from 'react-router-dom';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import { handleAuthError } from '../../../../utils/errorHandlers';

const SFOps = (props) => {

    const navigate = useNavigate()
    const [importedSobjectNames, setImportedSobjectNames] = useState([])
    const [availableSobjectNames, setAvailableSobjectNames] = useState([])
    const [filteredAvailableSobjectNames, setFilteredAvailableSobjectNames] = useState([])
    const [importPopoverTrigger, setImportPopoverTrigger] = useState(false)
    const [selectedSobjectName, setSelectedSobjectName] = useState("")
    const [selectedSobjectFilds, setSelectedSobjectFields] = useState([])
    const [fields, setFields] = useState({
        email: "-",
        phone: "-"
    })
    const [isUnique,setISUnique] = useState(false)
    const [listName,setListName] = useState("")
    const [listNames,setListNames] = useState([])
    
    useEffect(() => {
        (async ()=>{
            const _listname = await fetchListNames()
            setListNames(_listname)
        })();

        (async () => {
            const _importedSobjectNames = await fetchImportedSobjectNames()
            if (_importedSobjectNames.isError === true) {
                toast.warn(importedSobjectNames.error)
            } else {
                console.log(_importedSobjectNames.importedSobjectNames)
                setImportedSobjectNames(_importedSobjectNames.importedSobjectNames)
            }
        })();
        axios.get(apiURL + '/api/customers/available_sobject_names', { withCredentials: true })
            .then((response) => {
                setAvailableSobjectNames(response.data.data.available_sobject_names)
                setFilteredAvailableSobjectNames(response.data.data.available_sobject_names)
            })
            .catch((err) => {
                if(err.response.data?.from === "SF"){
                    props.setIsLoggedInSF(false)
                    return
                }
                toast.warn("Error fetching names from salesforce")
                handleAuthError(err)
            })

    }, [])

    const handleSearchTextChanged = (e) => {
        setFilteredAvailableSobjectNames(availableSobjectNames.filter((val) => val.name.includes(e.target.value)))
    }

    const handleImportObjectClick = (e) => {
        console.log(e.target.id)
        axios.get(apiURL + '/api/customers/sobject_field_names' + `/${e.target.id}`, { withCredentials: true })
            .then((response) => {
                setSelectedSobjectFields(response.data.data.sobject_field_names)
            })
            .catch((err) => {
                if(err.response.data?.from === "SF"){
                    props.setIsLoggedInSF(false)
                    return
                }
                toast.warn("Error occured while fetching sobject fields from salesforce")
                handleAuthError(err)
            })
        setSelectedSobjectName(e.target.id)
        setImportPopoverTrigger(true)
    }

    const handleFieldsChanged = (e) => {
        switch (e.target.id) {
            case "phoneField": setFields({ ...fields, phone: e.target.value }); break;
            case "emailField": setFields({ ...fields, email: e.target.value }); break;
            default: break;
        }
    }

    const handleImportContactsClicked = (e) => {
        if(!listName || listName.trim() === "") {
            toast.warn("Empty name not allowed")
            return
        }
    
        if (fields.phone === fields.email) {
            toast.warn("Please select different fields.")
            return
        }

        if (fields.phone === "-") {
            toast.warn("Phone field is mandatory")
            return
        }

        const id = toast.loading("Importing contacts")
        axios.post(apiURL + '/api/contacts/import_from_sf', {
            sobject_name: selectedSobjectName,
            phone_field: fields.phone,
            list_name : listName,
            ...(fields.email !== "-") && ({ email_field: fields.email })
        }, {
            withCredentials: true
        }).then(async (response) => {
            toast.update(id, { render: "Contacts imported successfully", type: "success", isLoading: false, autoClose: 1500 })
            setFields({
                email : "-",
                phone : "-"
            })
            setListName("")
            setImportPopoverTrigger(!importPopoverTrigger)
            const _importedSobjectNames = await fetchImportedSobjectNames()
            if (_importedSobjectNames.isError === true) {
                toast.warn(importedSobjectNames.error)
            } else {
                setImportedSobjectNames(_importedSobjectNames.importedSobjectNames)
            }
        }).catch((err) => {
            toast.update(id, { render: "Import not done", type: "error", isLoading: false, autoClose: 1500 })
            if(err.response.data?.from === "SF"){
                props.setIsLoggedInSF(false)
                return
            }
            handleAuthError(err)
        })
    }

    const handleListNameChanged = (e) =>{
        setListName(e.target.value.trim())
        setISUnique(listNames.includes(e.target.value)?false:true)
    }

    return (
        <div className='container-fluid h-100'>
            <ToastContainer />
            <div className='row h-100'>
                <div className='col h-100 d-flex justify-content-center align-items-top'>
                    <div className='card' style={{ maxHeight: "600px", overflowY: "scroll", borderRadius: "10px" }}>
                        <h2 className='card-header bg-light'>Imported Objects</h2>
                        {(!importedSobjectNames) ? <div>Test</div> :

                            <div className='card-body'>

                                <ul className='list-group list-group-flush' style={{ maxHeight: "500px", overflowY: "scroll" }}>
                                    {
                                        importedSobjectNames?.map((val, idx) => {
                                            return (
                                                <li className='list-group-item d-flex flex-column justify-content-start align-items-left'>
                                                   <div className='mb-2'><h6>Object Name </h6>{val.sobjectName}</div>
                                                   <div className='mb-2'><h6>List Name </h6>{val.listName}</div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        }
                    </div>
                </div>
                <div className='col h-100 d-flex justify-content-center align-items-top'>
                    <div className='form w-75 mt-3'>
                        <div className="input-group" style={{ height: "45px" }}>
                            <span className="input-group-text"><SearchIcon /></span>
                            <input type="text" className="form-control" placeholder="Search for available objects in your org" onChange={(e) => handleSearchTextChanged(e)} />
                        </div>
                        <ul className='list-group list-group-flush' style={{ maxHeight: "600px", overflowY: "scroll" }}>
                            {
                                filteredAvailableSobjectNames?.map((val, idx) => {
                                   
                                    return (
                                        <li className="list-group-item w-100 " id={val.name} onClick={(e) => handleImportObjectClick(e)} >
                                            <div className='d-flex flex-row justify-content-between h-100 w-100' style={{ pointerEvents: "none" }}>
                                                <div>
                                                    {val.name}
                                                </div>
                                                <div>
                                                    <ImportContactsIcon className='exists' style={{pointerEvents:"none"}} />
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
            <Popover trigger={importPopoverTrigger} setTrigger={setImportPopoverTrigger}>
                <div className='container-fluid w-100'>
                    <div className='row'>
                        <h2 className='col w-100 border-bottom'>
                            Import Contacts
                        </h2>
                    </div>
                    <div className='row'>
                        <div className='col d-flex justify-content-between align-items-center w-100'>
                            <div className='d-flex flex-column m-3'>
                                <label className='form-label'>Object Name</label>
                                <span><strong>{selectedSobjectName}</strong></span>
                            </div>



                            <div className='d-flex flex-column m-3'>
                                <label className='form-label'>Email Field</label>
                                <select id="emailField" onChange={(e) => handleFieldsChanged(e)}>
                                    <option value="-">-</option>
                                    {
                                        selectedSobjectFilds?.map((val, idx) => {
                                            return (
                                                <option value={val}>{val}</option>
                                            )

                                        })
                                    }
                                </select>
                            </div>

                            <div className='d-flex flex-column m-3 '>
                                <label className='form-label'>Phone Field</label>
                                <select id="phoneField" onChange={(e) => handleFieldsChanged(e)}>
                                    <option value="-">-</option>
                                    {
                                        selectedSobjectFilds?.map((val, idx) => {
                                            return (
                                                <option value={val}>{val}</option>
                                            )

                                        })
                                    }
                                </select>
                            </div>
                            <div className='d-flex flex-column m-3 '>
                                <label className='form-label'>List Name</label>
                                <span><input type="text" className="form-control" onChange={(e)=>handleListNameChanged(e)}/></span>
                               
                            </div>
                            <button type="button" className='btn btn-primary' onClick={(e) => handleImportContactsClicked(e)} disabled={!isUnique}>Import Contacts</button>

                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                        </div>
                        <div className='col'>
                        </div>
                        <div className='col'>
                        </div>
                        <div className='col mb-3 d-flex flex-row justify-content-center align-items-center'>
                            <div>{listName.trim()!==""?isUnique?"✅":`❌ Select a unique name`:null}</div>
                        </div>
                        <div className='col'>
                        </div>
                    </div>
                </div>
            </Popover>
        </div>
    )
}

export default SFOps