
// import React, { useEffect, useState } from "react";
// import { database } from "./FirebaseConfig";
// import { get, ref } from "firebase/database";
// import { Container, Row, Col } from "react-bootstrap";
// import "./fetchdata.css"



// const DataTable = () => {
//   const [users, setUsers] = useState([]);

//   useEffect(() => {
//     const usersRef = ref(database, 'dashboardauth');
//     get(usersRef)
//       .then((snapshot) => {
//         if (snapshot.exists()) {
//           const usersArray = Object.entries(snapshot.val()).map(
//             ([id, data]) => ({
//               id,
//               ...data,
//             })
//           );
//           setUsers(usersArray);
//         } else {
//           console.log("no data available");
//         }
//       })
//       .catch((error) => {
//         console.error(error);
//       });
//   }, []);

//   return (
//     <Container className="pt-4">
//       <h5 className="mt-4 mb-3" style={{ color: "white" }}>
//         List of Users
//       </h5>
//       <Row>
//         <Col xs={12}>
//           <div className="table-responsive">
//             <table className="table table-bordered table-striped w-100 ">
//               <thead>
//                 <tr className="throw">
//                   <th
//                     className="py-3 px-3"
//                     style={{ backgroundColor: "#71748c", color: "black" }}
//                   >
//                     Seq No
//                   </th>
//                   <th
//                     className="py-3 px-3"
//                     style={{ backgroundColor: "#71748c", color: "black" }}
//                   >
//                     First Name
//                   </th>
//                   <th
//                     className="py-3 px-3"
//                     style={{ backgroundColor: "#71748c", color: "black" }}
//                   >
//                     Last Name
//                   </th>
//                   <th
//                     className="py-3 px-3"
//                     style={{ backgroundColor: "#71748c", color: "black" }}
//                   >
//                     Email
//                   </th>
//                   <th
//                     className="py-3 px-3"
//                     style={{ backgroundColor: "#71748c", color: "black" }}
//                   >
//                     Password
//                   </th>

//                   <th
//                     className="py-3 px-3"
//                     style={{ backgroundColor: "#71748c", color: "black" }}
//                   >
//                     Phone
//                   </th>

//                   <th
//                     className="py-3 px-3"
//                     style={{ backgroundColor: "#71748c", color: "black" }}
//                   >
//                     Company Name
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {users.map((user, index) => (
//                   <tr key={user.id} style={{ backgroundColor: "#33375d" }}>
//                     <td className="py-3 px-3">{index + 1}</td>
//                     <td className="py-3 px-3">{user.firstName}</td>
//                     <td className="py-3 px-3">{user.lastName}</td>
//                     <td className="py-3 px-3">{user.email}</td>

//                     <td className="py-3 px-3">{user.password}</td>
//                     <td className="py-3 px-3">{user.phone}</td>

//                     <td className="py-3 px-3">{user.companyName}</td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//         </Col>
//       </Row>
//     </Container>
//   );
// };

// export default DataTable;






import React, { useEffect, useState } from "react";
import { auth, database } from "./FirebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import { ref, get } from "firebase/database";
import { Alert } from "react-bootstrap";

const DataTable = (props) => {

  return (
    <div className="container " style={{ color: "white" }}>
      {props.customer.companyName ? (
        <div className="col d-flex justify-content-center">
          <div>
            <div style={{ maxWidth: "fit-content" }}>User Profile</div>
            <div style={{ maxWidth: "fit-content" }}>
              Company Name: {props.customer.companyName}
            </div>
          </div>
        </div>
      ) : (
        <Alert variant="danger" style={{ padding: "10px" ,margin:"10px"}}>
          <Alert.Heading>Error !</Alert.Heading>
          <p className="m-0">No user is signed in or company name not found</p>
        </Alert>
      )}
    </div>
  );
};

export default DataTable;
