import React from 'react'
import EmailHome from './EmailHome';
import { Col, Container, Row } from 'react-bootstrap';
import Sidebar from '../../components/Sidebar';
import Navbar from '../../components/Navbar';

const Emailindex = () => {
  return (
    <div className="h-100 container-fluid" style={{ gap: "20px" }}>
      <div className="h-100 row flex">

        <div className="h-100 col " style={{ backgroundColor: "rgb(238,238,238" }}>
          {/* <div className="row">
            <div className="col align-items-center">
              <DataTable customer={customer} />
            </div>
          </div> */}
          <div className="row">
            <div className="col-2"></div>
            <div className="col-8">
              {/* <FileSelector files={files}/> */}
              <Container className="d-flex justify-content-center ">
                <Row className=" ">
                  {/* Left Column with Form */}
                  <Col className="left-column">
                                          <EmailHome />
                  </Col>

                  {/* Right Column with Image */}
                  {/* <Col md={6} className="right-column">
                    <img
                      src="call.png"
                      alt="Placeholder"
                      className="img-fluid"
                      style={{ height: "50%", width: "50%" ,borderRadius:"40%",padding:"10px"}}
                    />
                  </Col> */}
                </Row>
              </Container>
            </div>
            <div className="col-2"></div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default Emailindex