import React from "react";
import './resetPassword.css'
import { Button } from "react-bootstrap";
import {ToastContainer, toast} from "react-toastify"
import { useState } from "react";
import axios from "axios";
import apiURL from "../../apiURL";
import { useNavigate } from "react-router-dom";

const ResetPassword = ()=>{

    const navigate = useNavigate()
    const [disable, setDisable] = useState(false)

    const handleSubmit = (e)=>{
        e.preventDefault()
        const password = e.target.password.value
        const confirmPassword = e.target.confirmPassword.value

        if(password!==confirmPassword){
            toast.warn("Both passwords are not identical.")
        } else {
            const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&^])[A-Za-z\d@.#$!%*?&]{8,15}$/
            if(regex.test(password)){
                axios.put(apiURL + '/api/auth/reset_password',{"new_password":password},{withCredentials:true})
                    .then((response)=>{
                        toast.success("Password reset succesfully. Redirecting ....")
                        setTimeout(()=>{
                            navigate('/login')
                        },2500)
                    })
                    .catch((err)=>{
                        if(err.response.status === 401) {
                            toast.warn("Unauthorized")
                            return
                        }
                        toast.warn("Opps! Error occured")
                    })
            } else {
                toast.warn('1 each of Capital Letter, Special Character and Digit Required. Length must be between 8 and 15 characters.')
            }
        }
    }

    return(
       <div className="rp-container">
            <ToastContainer></ToastContainer>
            <div className="rp-form-container">
                <div className="rp-form-header">
                    <h4>Confirm new password</h4>
                </div>
                <form className="rp-form" onSubmit={(e)=>handleSubmit(e)}>
                    <input type="password" placeholder="new password" name="password"></input>
                    <input type="password" placeholder="confirm password" name="confirmPassword"></input>
                    <Button type="submit" disabled={disable}>Reset Password</Button>
                </form>
            </div>
       </div>
    )
}

export default ResetPassword