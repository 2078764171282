// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAuth } from "firebase/auth";

// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyAPNYSn4pz02n1PiAMzOSHofXESMOMHRos",
//   authDomain: "dashboardauth-cd17a.firebaseapp.com",
//   projectId: "dashboardauth-cd17a",
//   storageBucket: "dashboardauth-cd17a.appspot.com",
//   messagingSenderId: "893450386369",
//   appId: "1:893450386369:web:8a7a85e3dcd4607622cd40",
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// export const database = getAuth(app);




// firebase.js
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyAPNYSn4pz02n1PiAMzOSHofXESMOMHRos",
  authDomain: "dashboardauth-cd17a.firebaseapp.com",
  projectId: "dashboardauth-cd17a",
  storageBucket: "dashboardauth-cd17a.appspot.com",
  messagingSenderId: "893450386369",
  appId: "1:893450386369:web:8a7a85e3dcd4607622cd40",
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const auth = getAuth(app);

export { database, auth };

// export { database }; // Ensure you export the database object


// firebaseConfig.js

// import { initializeApp } from "firebase/app";
// import { getDatabase } from "firebase/database";
// import { getAuth } from "firebase/auth";

// const firebaseConfig = {
//   apiKey: "AIzaSyAPNYSn4pz02n1PiAMzOSHofXESMOMHRos",
//   authDomain: "dashboardauth-cd17a.firebaseapp.com",
//   projectId: "dashboardauth-cd17a",
//   storageBucket: "dashboardauth-cd17a.appspot.com",
//   messagingSenderId: "893450386369",
//   appId: "1:893450386369:web:8a7a85e3dcd4607622cd40",
// };

// const app = initializeApp(firebaseConfig);
// const database = getDatabase(app);
// const auth = getAuth(app);

// export { database, auth };
