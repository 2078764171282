import React, { useState } from "react";
import axios from "axios";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "./customer.css"
import apiURL from "../../../../apiURL";
import { fetchListNames } from "../../../../utils/fetchers";
import { handleAuthError } from "../../../../utils/errorHandlers";

const Customers = () => {
  const [file, setFile] = useState(null);
  const [name, setName] = useState("");
  const [uploadMessage, setUploadMessage] = useState("");
  const [isUnique,setIsUnique] = useState("")
  const [fileNames,setFileNames] = useState([])

  useState(()=>{
    (async()=>{
      const _fileNames = await fetchListNames()
      setFileNames(_fileNames)
    })();
  },[])

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    // Check if a file is selected
    if (selectedFile) { 
      // Check if the selected file type is CSV
      if (selectedFile.type === "text/csv") {
        setFile(selectedFile);
        setUploadMessage("");
      } else {
        setFile(null);
        setUploadMessage("Please select a CSV file only");
      }
    }
  };

  const handleNameChange = (e) => {
    setIsUnique(!fileNames.includes(e.target.value.trim()))
    setName(e.target.value.trim());
  };

  const handleUpload = async () => {

    const endpoint = "/api/files/upload_contacts"

    try {
      // Check if a file is selected before attempting to upload
      if (!file) {
        setUploadMessage("Please select a CSV file.");
        return;
      }

      const formData = new FormData();
      formData.append("file", file);
      formData.append("name", name);

      // Replace 'YOUR_API_ENDPOINT' with the actual API endpoint
      const response = await axios.post(apiURL + endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });

      setUploadMessage("File uploaded successfully");
      console.log("File uploaded successfully", response.data);
    } catch (error) {
      setUploadMessage("Error uploading file");
      console.error("Error uploading file", error);
      handleAuthError(error)
    }
  };

  return (


    <Container fluid className="d-flex justify-content-center py-5">
      <Row className="w-65">
        {/* Left Column with Form */}
        <div className="cust-form-container">
          <h5 className="py-3 text-center">Upload File</h5>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Upload File</Form.Label>
            <Form.Control
              type="file"
              accept=".csv"
              onChange={handleFileChange}
            />
          </Form.Group>

          <Form.Group controlId="formTextInput" className="mb-3">
            <Form.Label>Name of File</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter text"
              value={name}
              onChange={handleNameChange}
            />
          </Form.Group>

          <div className="d-grid gap-2 my-5">
            <Button
              variant="primary"
              type="button"
              onClick={handleUpload}
              style={{
                backgroundColor: "#e2923b",
                padding: "10px",
                border: "none",
                color: "white",
                fontWeight: "bold",
                borderRadius: "10px",
              }}
              disabled={!isUnique}
            >
              Submit
            </Button>
            <div>
              {name!==""?
              <p>{isUnique?"✅":`❌ Select a unique name`}</p>
              :null}
            </div>
          </div>

          {uploadMessage && <p>{uploadMessage}</p>}
        </div>
      </Row>
    </Container>
  );
};

export default Customers;

