import axios from "axios";
import apiURL from "../apiURL";
 

async function fetchImportedSobjectNames(){
    try {
        const response = await axios.get(apiURL + '/api/customers/imported_sobject_names',{withCredentials:true})
        return {
            isError : false,
            importedSobjectNames : response.data.data.imported_sobject_names
        }
    }catch(err){
        return {
            isError : true,
            error : err
        }
    }
}

const fetchListNames =  async()=>{
    try{
  
      const response = await axios({
        method : "get",
        url : apiURL + "/api/contacts/list_names",
        withCredentials : true
      })
  
      return response.data.data.list_names
    }catch(err){
      throw new Error("Error while fetching list names .",{cause : err})
    }
}


async function fetchContacts() {
  try {
    const response = await axios({
      method : "get",
      url : apiURL + "/api/contacts",
      withCredentials : true
    })
    return response.data.data.contacts
  }catch(err){
    throw err
  }
}

async function fetchListContactCounts() {
  try {
    const response = await axios({
      method : "get",
      url : apiURL + '/api/contacts/lists_contacts_details',
      withCredentials : true
    })
    return response.data.data.list_contact_details
  }catch(err){
    throw err
  }
}


function fetchContactsForList(listName) {

  return new Promise((resolve,reject)=>{
    axios.get(apiURL + '/api/contacts/get_contacts_from_list' + `/${listName}`,{withCredentials:true})
    .then((response)=>{
      resolve(response.data.data.contacts)
    }).catch((err)=>{
      reject(err)
    })
  })
}

export {
    fetchImportedSobjectNames,
    fetchListNames,
    fetchContacts,
    fetchListContactCounts,
    fetchContactsForList
}