import axios from "axios"
import apiURL from "../../../apiURL"


const fetchData = async (startDate,endDate) => {


    const callerId = "01140844551"
    // const startDate = "2024-01-09"
    // const endDate = "2024-01-10"
    const endpoint = `/api/calls/insights/${callerId}/${startDate}/${endDate}`
  
    console.log(apiURL+endpoint)
  
    return new Promise((resolve,reject)=>{
      axios.get(apiURL + endpoint, { withCredentials: true })
      .then((response) => {
        console.log(response.data.data)
        resolve(response.data.data)
      }).catch((err) => {
        reject(err)
      })
    })
  
}
  
const setData = async (params) => {
  
    const callLengthPercentagesNameMapping = {
      "lt5" : "<5",
      "gt5" : ">5",
      "lt1": "<1"
    }
  
    try{
        
      console.log("Test", params)
      const responseData = await fetchData(params.startDate,params.endDate)
      console.log(responseData.call_status_percentages)
  
  
      const dataForPieChart = []
      const tempCallLengthPercentages = []
      const tempCallsPerWeekday = []
      const tempCallsPerHourOfDay = []
  
      for (const key in responseData.call_status_percentages) {
        const tempObject = {
          name: key,
          value: parseFloat((responseData.call_status_percentages[key]).toFixed(2))
        }
        dataForPieChart.push(tempObject)
      }
  
  
      for (const key in responseData.call_length_percentages) {
        const tempObject = {
          name: callLengthPercentagesNameMapping[key],
          value: parseFloat((responseData.call_length_percentages[key]).toFixed(2))
        }
        tempCallLengthPercentages.push(tempObject)
      }
  
      for (const key in responseData.calls_per_weekday) {
        const tempObject = {
          name: key,
          value: responseData.calls_per_weekday[key]
        }
        tempCallsPerWeekday.push(tempObject)
      }
  
      for (const key in responseData.calls_per_hour_of_the_day) {
        const tempObject = {
          name: key,
          value: responseData.calls_per_hour_of_the_day[key]
        }
        tempCallsPerHourOfDay.push(tempObject)
      }
  
      params.setTotalCalls(responseData.total_calls)
      params.setCallLengthPercentages(tempCallLengthPercentages)
      params.setTestDataPieChart(dataForPieChart)
      params.setCallsPerWeekday(tempCallsPerWeekday)
      params.setCallsPerHourOfDay(tempCallsPerHourOfDay)
      params.setIsReady(true)
  
    }catch(err){
      throw err
    }
}

export default setData