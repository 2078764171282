import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Nav } from "react-bootstrap";
import "./login.css";
import {
  faFacebook,
  faApple,
  faGoogle,
} from "@fortawesome/free-brands-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, database } from "../../components/FirebaseConfig";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useCookies } from "react-cookie";
import apiURL from "../../apiURL";

const LoginForm = () => {

 const navigate = useNavigate()
 const [login, setLogin] = useState(false);
 const history = useNavigate();
 const [cookie,setCookie] = useCookies(['verb-auth-token'])

 const endPoint = "/api/auth/"

 useEffect(()=>{
  axios.get(apiURL+endPoint,{withCredentials:true})
  .then((res)=>{
    if(res.data.success){
      navigate('/app/calls/home')
    }
  })
  .catch((err)=>{
    console.log("ERROR")
  })
 },[])

  
  const handlesubmit = (e) => {
     e.preventDefault();
     const email = e.target.email.value.trim();
     const password = e.target.password.value.trim();

     if(email == "" || password == "") {
      toast.warn("Both the fields are required.",{autoClose:2500,position:"top-center"})
      return
     }

     const endpoint = "/api/auth/login"
    axios.post(apiURL+endpoint,{email:email,password:password},{withCredentials : true})
      .then((res)=>{
        if(res.data.success){
          console.log(res)
          toast.success("Login Successfull.",{autoClose:2500,position:"top-center"})
          setTimeout(()=>{navigate('/app/calls/home')},1500)
        }
      }).catch((err)=>{
        if (err.response.status === 404) {
          toast.warn("No user with this email exists.",{autoClose:2500,position:"top-center"})
        } else if (err.response.status === 401) {
          toast.warn("Password Incorrect.",{autoClose:2500,position:"top-center"})
        }
      })

    // navigate("/home")
  };
  return (
    <Form
      className="d-flex flex-column align-items-center "
      style={{ width: "100%" }}
      onSubmit={(e) => handlesubmit(e)}
    >
      <ToastContainer/>
      <h2 className="text-center">Login</h2>
      <p>
        Don't have an account ? <Link to="/signup">Sign Up</Link>
      </p>

      <Form.Group controlId="formBasicEmail" className="w-100 my-2 ">
        {/* <Form.Label>Email address</Form.Label> */}
        <Form.Control type="email" placeholder="Enter email" name="email" />
      </Form.Group>

      <Form.Group controlId="formBasicPassword" className="w-100 my-2">
        {/* <Form.Label>Password</Form.Label> */}
        <Form.Control type="password" placeholder="Password" name="password" />
      </Form.Group>

      <Form.Group
        controlId="formBasicCheckbox"
        className="w-100 d-flex justify-content-between my-4"
      >
        <Form.Text className="text-muted">
          <Link to='/forgot_password'>Forgot Password?</Link>
        </Form.Text>
      </Form.Group>

      <Form.Group className="w-100"></Form.Group>

      <Button
        variant="primary"
        type="submit"
        className="submitbtn w-100"
        // onClick={handleClick}
      >
        login
      </Button>
    </Form>
  );
};

const Login = () => {
  return (
    <>
      {/* <Nav as="ul" className="" style={{ backgroundColor: "#0F052A" }}>
        <Nav.Item as="li">
          <FontAwesomeIcon icon="home" style={{ color: "blue" }} />

          <Link to="/" className="nav-link">
            {" "}
            Home
          </Link>
        </Nav.Item>
      </Nav> */}
      <Container className=" d-flex align-items-center justify-content-center mt-5 ">
        <Row className="">
          <Col
            sm={12}
            md={6}
            className="rightcol d-flex justify-content-center align-items-center"
            // style={{ border: "2px solid red" }}
          >
            {/* <div className="imgdiv ">
              <img
                src="bluelogo.png" // Replace with the path to your logo
                alt="Logo"
                style={{ width: "150px", marginBottom: "20px" }}
                className=""
              />
            </div> */}
            <LoginForm />
          </Col>
          <Col
            md={6}
            className="p-5 d-flex justify-content-center align-items-center "
            // style={{ border: "2px solid green" }}
          >
            <img
              src="login2.jpg" // Replace with the path to your image
              alt="Responsive"
              className="img-fluid"
              style={{ borderRadius: "20px" }}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;
