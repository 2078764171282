/**
 * Checks for a 401 error from Verbalyze REST API & redirects to login.
 * @param {Error} err 
 */
function handleAuthError(err){
    if(err.response?.status===401){
        window.location.replace('/login')
    }
}

export {
    handleAuthError
}