import { useEffect, useState } from 'react'
import './sfLogin.css'
import { useParams, useSearchParams } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress';
import apiURL from '../../../../apiURL';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

const SFLogin = (props) => {

    const [searchParams,setSearchParams] = useSearchParams()
    const [sfCode, setSfCode] = useState(null)
    const [loginURLSF,setLoginURLSF] = useState(null)

    useEffect(()=>{
        axios.get(apiURL + '/api/customers/me', {withCredentials:true})
            .then((response)=>{
                if(response.data.data.user.sfDetails && response.data.data.user.sfDetails.isLoggedIn){
                    props.setIsLoggedInSF(true)
                }
            }).catch((err)=>{
                toast.warn("Some error occures")
            })
    },[])

    useEffect(()=>{
        const sfLoginURL = new URL('https://verbalyze-dev-ed.develop.my.salesforce.com/services/oauth2/authorize') 
        sfLoginURL.searchParams.append("client_id",process.env.REACT_APP_client_secret_SF)
        sfLoginURL.searchParams.append("redirect_uri",process.env.REACT_APP_redirect_uri_SF)
        sfLoginURL.searchParams.append("response_type","code")
        setLoginURLSF(sfLoginURL.toString())
    },[])

    useEffect(()=>{
        if(searchParams.get('code')){
            const code = searchParams.get('code')
            console.log(searchParams.get('code'))
            setSfCode(searchParams.get('code'))
            searchParams.delete('code')
            setSearchParams(searchParams)
            axios.post(apiURL+'/api/auth/login_sf',{
                auth_code : code
            },{
                withCredentials : true
            }).then((response)=>{
                props.setIsLoggedInSF(true)
            }).catch((err)=>{
                props.setIsLoggedInSF(false)
                toast.warn(err.message)
                toast.warn(err.response?.data?.message)
            })
        }
    },[])


    if(sfCode){
        return (
            <div className='container-fluid h-100 d-flex flex-column align-items-center justify-content-center'>
                <ToastContainer />
                Loading ...
                <CircularProgress />
            </div>
        )
    } else {
        return (
            <div className='container-fluid h-100 d-flex align-items-center justify-content-center'>
                <div className='card rounded' >
                    <img src='/sfloginimage.jpeg' className="img-card-top rounded" alt='salesforceloginimage' height={"300px"} />
                    <div className='card-body'>
                        <h4 className='card-title mb-3'>Connect with salesforce</h4>
                        <a className='btn btn-primary rounded-25 w-100 mb-3' href={loginURLSF?loginURLSF:"#"}>Login with salesforce</a>
                    </div>
                </div>
    
            </div>
    
        )

    }



}

export default SFLogin