import React, { useEffect, useState } from 'react'
import { PieChart, Pie, ResponsiveContainer, Cell, BarChart, CartesianGrid, XAxis, YAxis,Bar, LineChart,Line } from 'recharts';
import './index.css'
import axios from 'axios';
import PhoneIcon from '@mui/icons-material/Phone';
import { Button, Toast } from 'react-bootstrap';
import { ToastContainer,toast } from 'react-toastify';
import DateSelector from './DateSelector';

const renderLabel = (entry) => {
  return entry.name
}



const Insights = () => {

  const [testDataPieChart, setTestDataPieChart] = useState([])
  const [totalCalls, setTotalCalls] = useState(0)
  const [callLengthPercentages, setCallLengthPercentages] = useState([])
  const [callsPerWeekday, setCallsPerWeekday] = useState([])
  const [callsPerHourOfDay, setCallsPerHourOfDay] = useState([])
  const [isReady, setIsReady] = useState(false)

  let setters = {
    setTotalCalls : setTotalCalls,
    setCallLengthPercentages : setCallLengthPercentages,
    setTestDataPieChart : setTestDataPieChart,
    setCallsPerWeekday : setCallsPerWeekday,
    setCallsPerHourOfDay : setCallsPerHourOfDay,
    setIsReady : setIsReady
  }

  const pieChartColors = [
    "#A789EA",
    "#303279",
    "#4F4794",
    "#7E6BC6"
  ]

  const callLengthPercentagesNameMapping = {
    "lt5" : "<5",
    "gt5" : ">5",
    "lt1": "<1"
  }

  return (

    <div className='insightContainer'>
      <ToastContainer></ToastContainer>
      <div className='insightContent'>
        <div className='componentContainer'>
          {!isReady?<DateSelector {...setters} ></DateSelector> : 
          <>
          <div className='subComponentContainer1'>
            <div className='component-total-calls'>
              <PhoneIcon />
              <h4 style={{fontFamily : "Helvetica"}}>Total Calls</h4>
              <h6>{totalCalls}</h6>
            </div>
            <DateSelector {...setters} isPartOfInsights={true}></DateSelector>
          </div> 
          <div className='subComponentContainer2'>
            <div className='component-call-status'>
              <div style={{fontFamily : "Helvetica",borderBottom: "1.5px solid skyblue"}}>
                Call Status
              </div>
              <center style={{justifyItems : "center"}}>
                <PieChart height={250} width={300}>
                  <Pie data={testDataPieChart} cx="50%" cy="50%" dataKey="value" fill='blue' innerRadius={50} outerRadius={80} nameKey="name" label={renderLabel} labelLine={true}>
                    {testDataPieChart.map((call,idx) => <Cell key={idx} fill={pieChartColors[idx]} stroke=''></Cell>)}
                  </Pie>
                </PieChart>
              </center>
            </div>
            <div className="component-calls-per-weekday">
              <div style={{fontFamily : "Helvetica",borderBottom: "1.5px solid #BBBBBB"}}>
                Calls Per Day
              </div>
              <BarChart height = {250} width={400} data={callsPerWeekday} >
                  <CartesianGrid strokeDasharray='1' />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Bar dataKey="value" fill="#A789EA" radius={[7,7,0,0]} />
                </BarChart>
            </div>
          </div> 
          <div className='subComponentContainer3'>
            <div className='component-call-length'>
                <div style={{fontFamily : "Helvetica",borderBottom: "1.5px solid skyblue"}}>
                    Call length
                </div>
                <center>
                <PieChart height={250} width={300}>
                  <Pie data={callLengthPercentages} cx="50%" cy="50%" dataKey="value" fill='blue' innerRadius={50} outerRadius={80} nameKey="name" label={renderLabel} labelLine={true}>
                    {callLengthPercentages.map((call,idx) => <Cell key={idx} fill={pieChartColors[idx]} stroke=''></Cell>)}
                  </Pie>
                </PieChart>
              </center>
            </div>
            <div className='component-calls-per-hour-of-day'>
              <div style={{fontFamily : "Helvetica",borderBottom: "1.5px solid gray"}}>
                    Calls Per Hour
              </div> 
              <LineChart height={250} width={300} data={callsPerHourOfDay}>
                <CartesianGrid strokeDasharray='1' />
                <XAxis dataKey="name" />
                <YAxis />
                <Line type="bump" dataKey="value" stroke="#7764BD" strokeWidth={3} dot={{fill:"#7764BD"}}/>
              </LineChart>
            </div>
          </div> 
          </>
        }
          </div>
        </div>
      </div>


  );
}

export default Insights