import React, { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import "./signup.css";
import { Link, useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { auth, database } from "../../components/FirebaseConfig";
import { createUserWithEmailAndPassword } from "firebase/auth";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import apiURL from "../../apiURL";

const SignupForm = () => {

  const initialValues = { name: "", email: "", phone: "", companyName: "", password: "" }
  const [formValues, setFormValues] = useState(initialValues)

  const history = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target
    console.log(name, value)
    const tempValues = formValues
    tempValues[name] = value
    console.log(tempValues);
  }



  const handlesubmit = (e) => {

    e.preventDefault();

    const customersEndpoint = '/api/customers'
    const fullURL = apiURL + customersEndpoint + '/register'


    // Extract values from the form
    const name = e.target.name.value.trim();
    const email = e.target.email.value.trim();
    const phone = e.target.phone.value.trim();
    const companyName = e.target.companyName.value.trim();
    const password = e.target.password.value.trim();

    // Use the values as needed
    console.log("Name:", name);
    console.log("Email:", email);
    console.log("Phone:", phone);
    console.log("Company Name:", companyName);


    //Input validation
    if (name === "" || email === "" || phone === "" || companyName === "" || password === "" || phone.length != 10) {
      if(phone.length != 10){
        toast.warn("Please enter a valid phone number")
      } else {
        toast.warn("All fields are required.")
      }
    } else {
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&^])[A-Za-z\d@.#$!%*?&]{8,15}$/
      if (regex.test(password)) {
        const customer = {
          name: e.target.name.value,
          email: e.target.email.value,
          phone: e.target.phone.value,
          companyName: e.target.companyName.value,
          password: e.target.password.value
        }

        axios.post(fullURL, customer)
          .then((res) => {
            if (res.data.success) {
              console.log(res.data)
              toast("User registered successfully.")
            }
          })
          .catch((err) => {
            if (err.response.status === 409) {
              console.log(err.response.data.message);
              toast("User already exists.")
            } else if (err.response.status === 400) {
              console.log(err.response.data.message);
              toast(err.response.data.message)
            }
          })

      } else {
        toast.info(`Password should contain : At least one lowercase alphabet, at least one uppercase alphabet, at least one digit, at least one special character, length between 8-15 characters.`)
      }
    }


    setErrorMessage(null); // Reset error message on each submission
  };

  return (

    <Form
      className="d-flex flex-column align-items-center "
      style={{ width: "100%" }}
      onSubmit={(e) => handlesubmit(e)}
    >
      <h2 className="text-center">Sign Up</h2>
      <p>
        Already have an account? <Link to="/login">Login</Link>{" "}
      </p>
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

      <Form.Group controlId="formBasicName" className="w-100 my-2 mt-4">
        <Form.Control type="text" placeholder="Enter Name" name="name" onChange={handleChange} />
      </Form.Group>

      <Form.Group controlId="formBasicEmail" className="w-100 my-2">
        <Form.Control type="email" placeholder="Enter Email" name="email" onChange={handleChange} />
      </Form.Group>

      <Form.Group controlId="formBasicPhone" className="w-100 my-2">
        <Form.Control type="tel" placeholder="Enter Phone" name="phone" onChange={handleChange} />
      </Form.Group>

      <Form.Group controlId="formBasicCompanyName" className="w-100 my-2">
        <Form.Control type="text" placeholder="Enter Company Name" name="companyName" onChange={handleChange} />
      </Form.Group>

      <Form.Group controlId="formBasicPassword" className="w-100 my-2">
        <Form.Control type="password" placeholder="Password" name="password" onChange={handleChange} />
      </Form.Group>



      <Form.Group className="w-100"></Form.Group>

      <Button variant="primary" type="submit" className="submitbtn w-100 mt-2">
        Sign Up
      </Button>
      <ToastContainer />
    </Form>
  );
};

const Signup = () => {
  return (
    <>
      {/* <Nav as="ul" className="" style={{ backgroundColor: "#0F052A" }}>
        <Nav.Item as="li">
          <FontAwesomeIcon icon="home" style={{ color: "blue" }} />

          <Link to="/home" className="nav-link">
            {" "}
            Home
          </Link>
        </Nav.Item>
      </Nav> */}
      <Container className="d-flex align-items-center justify-content-center mt-2">
        <Row className="">
          <Col
            sm={12}
            md={6}
            className="rightcol d-flex justify-content-center align-items-center"
          // style={{ border: "2px solid red" }}
          >

            <SignupForm />
          </Col>
          <Col
            md={6}
            className="p-5 d-flex justify-content-center align-items-center "
          // style={{ border: "2px solid green" }}
          >
            <img
              src="signup.jpg" // Replace with the path to your image
              alt="Responsive"
              className="img-fluid"
              style={{ borderRadius: "20px" }}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Signup;
