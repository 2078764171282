import React from "react";
import './stage.css'
import { Outlet } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";

const Stage = () =>{

    return(
        <div className="st-container">
            <div className="st-sidebar-container">
                <Sidebar />
            </div>
            <div className="st-navbar-container">
                <Navbar />
            </div>
            <div className="st-content-container">
                <Outlet></Outlet>
            </div>
        </div>
    )

}

export default Stage