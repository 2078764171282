// Col1.js
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Box from "./Box";
import PieChartComponent from "../../../components/Piechart";

const InsightContent = () => {
  const [apiData, setApiData] = useState(null);

  useEffect(() => {
    fetch("http://13.201.105.72/get_mail_status")
      .then((response) => response.json())
      .then((data) => {
        setApiData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <Container fluid>
      <Row className="d-flex m-2 align-items-stretch">
        {/* Left column with small boxes */}
        <Col xs={12} md={6} className="d-flex flex-column">
          <Row className="p-2 flex-grow-1">
            <Col xs={6} md={6}>
              <Box title="Total Mails Sent" value={apiData?.email_sent} />
            </Col>
            <Col xs={6} md={6}>
              <Box
                title="Unsubscribed Emails"
                value={apiData?.unsubscribed_mail}
              />
            </Col>
          </Row>
          <Row className="p-2 flex-grow-1">    
            <Col xs={6} md={6}>
              <Box
                title="Other Undelivered Mails"
                value={apiData?.other_undelivered_mails} 
              />
            </Col>
            <Col xs={6} md={6}>
              <Box
                title="Complaint List Mails"
                value={apiData?.complaint_list_mails}
              />
            </Col>
          </Row>
        </Col>

        {/* Right column with graph */}
        <Col xs={12} md={6} className="d-flex flex-column">
          <PieChartComponent data={apiData} className="flex-grow-1" />
        </Col>
      </Row>
    </Container>
  );
};

export default InsightContent;
