import './popover.css'

const Popover = (props) => {


    return (
        <>
        {
            props.trigger ? 
            <div className='popover-container'>
                <button type="button" className='btn btn-danger' onClick={(e)=>{props.setTrigger(false)}}>Close</button>
                <div className='popover-inner'>
                    {props.children}
                </div>
            </div>
            
            : null
        }
        </>
    )
}

export default Popover