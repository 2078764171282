// Navbar.js

import { signOut } from "firebase/auth";
import React, { useEffect } from "react";
import { Link, useHistory, useNavigate } from "react-router-dom";
import { auth, database } from "./FirebaseConfig";
import { useCookies } from "react-cookie";
import axios from "axios";
import {ToastContainer,toast} from "react-toastify"
import apiURL from "../apiURL";
import { useState } from "react";
import { handleAuthError } from "../utils/errorHandlers";
// import { logout, isAuthenticated } from "./authService";
// import { database, auth } from "./firebaseConfig";

const Navbar = (props) => {

  const [userName, setUserName]=useState(null)
  const [cookie,setCookie,removeCookie]=useCookies(['verb-auth-token'])
//   const history = useHistory();

//   const handleLogout = () => {
//     logout();
//     history.push("/login");
  // const history = useNavigate();
  
  // const handleClick = () => {
  //   signOut(database).then(val => {
  //     console.log(val,"val")
  //     history('/login')
  //   })
  // }

  useEffect(()=>{
    (async ()=>{
      try {
        const response = await axios.get(apiURL + '/api/customers/me', {withCredentials:true})
        setUserName(response.data.data.user.name)
      } catch(err) {
          toast.warn("Error occurred while setting up.")
          if(err){
            if(err.response){
              if(err.response.status){
                if(err.response.status===403 || err.response.status === 401) {
                  history('/login')
                }
              }
            }
          }
      }
    })()
  },[])

  const history = useNavigate(); // Initialize the useHistory hook

  const handleClick = () => {
 
    axios.post(apiURL+'/api/auth/logout',{},{ withCredentials : true})
      .then((response)=>{
        history('/login')
      })
      .catch((err)=>{
        handleAuthError(err)
        console.log(err)
        alert("Logout failed")
      })
    };



  return (
    <nav
      style={{
        width : "100%",
        display: "flex",
        justifyContent: "space-between",
        padding: "10px",
        backgroundColor: "#202453",
        color: "white",
        margin: "0",
        padding:"20px",
        alignContent: "center"
      }}
    >
      <div>
        {/* Replace 'logo.png' with the path to your logo image */}
        {/* <img
          src="logo.png"
          alt="Logo"
          style={{ height: "40px", width: "auto" }}
        /> */}
        <p style={{fontSize:"15px",fontWeight:"bold" ,marginBottom:"0",marginTop:"0px"}}>{userName?`Hi, ${userName}`:""} Welcome to the Dashboard</p>
      </div>
      <div>
        {/* {isAuthenticated() && ( */}
        <>
          {/* Link to the profile page */}
          <Link to="/profile" style={{ marginRight: "10px" }}>
            <img
              src="/user.png" // Replace with your profile icon
              alt="Profile"
              style={{ height: "30px", width: "auto" }}
            />
          </Link>
          {/* Logout button */}
          <button style={{color:"white" ,border:"none",background:"none"}} onClick={handleClick}>Logout</button>
        </>
        {/* )} */}
        <ToastContainer />
      </div>
    </nav> 
  );
};

export default Navbar;
