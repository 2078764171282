import React from "react";
import './stage.css'
import { Outlet } from "react-router-dom";

const ChatsStaging = () =>{
    return (
        <div className="chats-container">
            <Outlet></Outlet>
        </div>
    )
}

export default ChatsStaging