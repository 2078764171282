import { forwardRef, useState } from 'react'
import './verbTable.css'
import TableDropdown from '../tableDropdown/tableDropdown'

const VerbTable = forwardRef(({ headers, body, selectedNumbers, handleContactChecked,hiddenSerialNos }, ref) => {



    return (

        <div className='table-responsive verb-table-container'>
            <table className='table table-light table-striped text-center verb-table vertical-align-middle m-0'>
                <thead>
                    <tr>
                        <th><input type="checkbox" id="select_all" key={body?.length===selectedNumbers?.length ? "yes" : "no"} defaultChecked={body?.length===selectedNumbers?.length} onChange={(e) => handleContactChecked(e)} /></th>
                        {
                            hiddenSerialNos ? null : <th>Sr No</th>
                        }

                        {
                            headers?.map((val, idx) => {

                                return (
                                    <th>{val}</th>
                                )
                            })
                        }
                    </tr>
                </thead>
                <tbody ref={ref}>
                    {
                        body?.map((val, idx) => {

                            return (
                                <tr>
                                    <td>
                                        <input type="checkbox" key={selectedNumbers?.includes(val.number) ? "yes" : "no"} value={val.number} defaultChecked={selectedNumbers?.includes(val.number)} onChange={(e) => handleContactChecked(e)} />
                                    </td>
                                    {
                                        hiddenSerialNos ? null : <td>{idx + 1}</td>
                                    }
                                   
                                    {
                                        headers?.map((key, idx) => {
                                            return (
                                                <td>{val[key]}</td>
                                            )
                                        })
                                    }
                                    
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
})

export default VerbTable