import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import './tableDropdown.css'
import ListIcon from '@mui/icons-material/List';

const TableDropdown = (props) =>{
    return (
        <div className="dropdown mx-2" >
            <button type="button" className="btn p-0" data-bs-toggle="dropdown">
                <ListIcon style={{pointerEvents:"none"}}/>
            </button>
            <ul className="dropdown-menu bring-to-front">
                <li className='dropdown-item d-flex flex-row justify-content-start border-bottom' onClick={(e)=>props.handleHideSerialNos(e)}>{props.hiddenSerialNos ? "Unhide" : "Hide"} Serial Nos</li>
                <li className="dropdown-item d-flex flex-row justify-content-start border-bottom" onClick={(e)=>props.handleCall(e)}>Call selected contacts</li>
                <li className='dropdown-item d-flex flex-row justify-content-start border-bottom' onClick={(e)=>props.handleSendEmail(e)}>Mail selected contacts </li>
            </ul>
        </div>
    )
}

export default TableDropdown