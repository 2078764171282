import React, { useMemo } from "react";
import { useState, useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import PhoneIcon from '@mui/icons-material/Phone';
import './ContactsTable.css'
import axios from "axios";
import { toast } from "react-toastify";
import apiURL from "../../apiURL";

const ContactsTable = (props) => {

    const [contacts, setContacts] =  useState([])


    const handleCheck = (e) => {
        const value = e.target.value
        console.log(value)
        if(e.target.checked) {
            props.setSelectedNumbers([...props.selectedNumbers,value])
        } else {
            props.setSelectedNumbers(props.selectedNumbers.filter((val)=>val != value))
        }
    } 


    useEffect(()=>{
        setContacts(props.contacts)
    },[])

    useEffect(()=>{
        const tempCallStatuses = {}
        for(const contact of contacts) {
            tempCallStatuses[contact.number]=false
        }
        props.setCallStatuses(tempCallStatuses)
    },[contacts])

    return (
        <table>
                <thead style={{backgroundColor:"#f2f2f2"}}>
                    <tr>
                        <th>Sr No.</th>
                        <th style={{display:"flex",justifyContent:"space-between",alignContent:"center"}}>
                            <center>Select</center>
                        </th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Call Status</th>
                    </tr>
                </thead>
                <tbody >
                {contacts.map((val,idx)=>{
                    return (
                    <tr key={idx} >
                        <td scope="row">{idx+1}</td>
                        <td><input type="checkbox" key={idx} value={val.number} onChange={(e)=>handleCheck(e)}></input></td>
                        <td>{val.first_name}</td>
                        <td>{val.last_name}</td>
                        <td>{val.email}</td>
                        <td>{val.number}</td>
                        <td>{props.callStatuses[val.number]? "Queued":"Unplaced"}</td>
                    </tr>
                    )
                })}
            </tbody>
        </table>
    )

}


export default ContactsTable

