import { Link } from 'react-router-dom'
import './notFound.css'
import HomeIcon from '@mui/icons-material/Home';

const NotFound = () =>{
    return (
        <div className="container-fluid h-100">
            <div className="not-found h-100 w-100 d-flex justify-content-center align-items-top">
                <Link to='/app/calls/home'><HomeIcon/>Take me home</Link>
            </div> 
        </div>
    )
}

export default NotFound