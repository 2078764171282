import React, { useState } from "react";
import FacebookLoginButton from "./facebookloginBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import { Container, Row, Col } from "react-bootstrap";
import DataTable from "../../components/FetchedData";
import "./chatshome.css"


const CallIndex = () => {
      const [customer, setCustomer] = useState({});

  return (
    <div className="h-100 container-fluid" style={{ gap: "20px" }}>
      <div className="h-100 row flex-nowrap">
        <div className="col " style={{ backgroundColor: "rgb(238,238,238)" }}>
          <div className="row">
            <div className="col align-items-center">
              <DataTable customer={customer} />
            </div>
          </div>
          <div className="row pt-5">
            <div className="col-2"></div>
            <div className="col-8">
              <Container className="d-flex justify-content-center ">
                <Row className="box-container ">
                  <Col className="left-column p-5">
                    <div className="">
                      <h5 className="py-3" style={{ textAlign: "center" }}>
                        <FontAwesomeIcon
                          icon={faFacebook}
                          style={{ fontSize: "50px", color: "blue" }}
                        />
                      </h5>

                      <FacebookLoginButton />
                    </div>
                  </Col>

                  {/* Right Column with Image */}
                  {/* <Col md={6} className="right-column">
                    <img
                      src="call.png"
                      alt="Placeholder"
                      className="img-fluid"
                      style={{ height: "50%", width: "50%" ,borderRadius:"40%",padding:"10px"}}
                    />
                  </Col> */}
                </Row>
              </Container>
            </div>
            <div className="col-2"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallIndex;
