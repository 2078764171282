// Box.js
import React from "react";
import { Card } from "react-bootstrap";

const Box = ({ title, value }) => {
  return (
    <Card
      style={{
        backgroundColor: "#202453",
        color: "white",
        height: "100%",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        padding: "20px",
      }}
    >
      <div>
        <p style={{fontSize:"17px"}}>{title}</p>
        <h1>{value}</h1>
      </div>
      {/* <Card.Body style={{ height: "100%" }}>
        <Card.Title></Card.Title>
        <Card.Text>{`Value: $`}</Card.Text>
      </Card.Body> */}
    </Card>
  );
};

export default Box;
