import { Link } from 'react-router-dom'
import './landing.css'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

const Landing = () =>{
    return (
        <div className='container fluid h-100 d-flex justify-content-center align-items-center'>
            <div className='card rounded'>
                <h2 className='card-header mb-3'>Upload Contacts</h2>
                <div className='card-body'>
                    <h4 className='card-title mb-3 d-flex align-items-center justify-content-start'><InsertDriveFileIcon /> Files </h4>
                    <Link type="button" className='btn btn-primary mb-3 w-100' to='file'>Files</Link>
                </div>
                <div className='card-body'>
                    <h4 className='card-title mb-3 d-flex align-items-center justify-content-start'> <SupportAgentIcon /> Connect to CRMs</h4>
                    <Link type="button" className='btn btn-info mb-3 w-100' to='salesforce'>Salesforce</Link>
                    <Link type="button" className='btn btn-info mb-3 w-100' to='#'>Zoho</Link>
                </div>
            </div>
        </div>
    )
}

export default Landing