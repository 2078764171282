import { useEffect, useRef, useState } from 'react'
import './superContacts.js'
import { fetchContacts, fetchContactsForList, fetchListContactCounts } from '../../../utils/fetchers.js'
import VerbTable from '../../../components/verbTable/verbTable.js'
import SuperContactsList from '../../../components/superContactsList/superContactsList.js'
import { toast } from 'react-toastify'
import axios from 'axios'
import apiURL from '../../../apiURL.js'
import { handleAuthError } from '../../../utils/errorHandlers.js'

const _ = require('lodash')


const SuperContacts = () => {

    const [listContactDetails, setListContactDetails] = useState([])
    const [test, setTest] = useState(null)
    const [tableHeaders, setTableHeaders] = useState(null)
    const [contacts, setContacts] = useState(null)
    const [selectedNumbers, setSelectedNumbers] = useState([])
    const [hiddenSerialNos, setHiddenSerialNos] = useState(true)

    const handleHideSerialNos = (e) => {
        setHiddenSerialNos(!hiddenSerialNos)
    }


    useEffect(() => {
        (async () => {
            const id = toast.loading('Fetching your lists ...')
            try {
                const _listContactDetails = await fetchListContactCounts()
                console.log(_listContactDetails)
                setListContactDetails(_listContactDetails)
                toast.update(id, {
                    render: "Setting up the contacts",
                    autoClose: 1500,
                    isLoading: false,
                    type: "success"
                })
            } catch (err) {
                toast.update(id, {
                    render: "Error fetching the lists",
                    autoClose: 1500,
                    isLoading: false,
                    type: "error"
                })
            }

        })()
    }, [])

    useState(() => {
        console.log("Changed")
    }, [selectedNumbers])

    const handleExpandClick = (e) => {
        setSelectedNumbers([])
        if (e.target.id === test) {
            setTest(null)
            setTableHeaders(null)
            setContacts(null)
            return
        }
        setTest(e.target.id)
        const id = toast.loading('Fetching contacts for list ...')
        fetchContactsForList(e.target.id)
            .then((contacts) => {
                setTableHeaders([...Object.keys(contacts[0]), "Call Status", "Email Status"])
                setContacts(contacts.map((val) => {
                    return ({
                        ...val,
                        "Call Status": "Unplaced",
                        "Email Status": "Not Sent"
                    })
                }))
                toast.update(id, {
                    render: "Setting up the table",
                    autoClose: 1500,
                    isLoading: false,
                    type: "success"
                })
            })
            .catch((err) => {
                toast.update(id, {
                    render: "Error fetching the lists",
                    autoClose: 1500,
                    isLoading: false,
                    type: "error"
                })
            })
    }

    const handleContactChecked = (e) => {
        console.log('-----++++++-------')
        console.log(e.target.id)
        if (e.target.id === "select_all") {
            setSelectedNumbers(e.target.checked === true ? [...contacts.map((val) => val.number)] : [])
        } else {
            if (e.target.checked) {
                const _selectedNumbers = [...selectedNumbers]
                _selectedNumbers.push(e.target.value)
                setSelectedNumbers(_selectedNumbers)
            } else {
                const _selectedNumbers = selectedNumbers.filter((val) => val !== e.target.value)
                setSelectedNumbers(_selectedNumbers)
            }

        }
    }

    const handleCall = (e) => {
        if (selectedNumbers.length === 0) {
            toast.warn("Please select a number to place calls")
            return
        }
        const id = toast.loading(`Placing request for ${selectedNumbers.length} ${selectedNumbers.length > 1 ? "calls" : "call"}`)
        const endPoint = "/api/calls/connect_numbers_to_call_flow"
        //Replace caller_id and app_id with actual ones
        //"caller_id" : "01140844551","app_id" : "730685"
        axios.post(apiURL + endPoint, { "caller_id": "01140844551", "app_id": "730685", numbers: selectedNumbers }, { withCredentials: true })
            .then((response) => {
                toast.update(id, { render: "Call request successfull.", isLoading: false, type: "success", autoClose: 500 })
                const updatedContacts = contacts.map((val) => {
                    if (selectedNumbers.includes(val.number)) {
                        val["Call Status"] = "Queued"
                    }
                    return val
                })
                setContacts(updatedContacts)
            }).catch((err) => {
                toast.update(id, { render: "Call request failed.", isLoading: false, type: "error", autoClose: 500 })
                handleAuthError(err)
            })
    }


    const handleSendEmail = (e) => {
        const contactsForNumbers = selectedNumbers.map((val, idx) => {
            return (contacts.find((_val) => _val.number === val))
        })
        console.log(contactsForNumbers)
    }


    return (
        <>
            <div className='container-fluid h-100'>
                <div className='row'>
                    <SuperContactsList listContactDetails={listContactDetails} setCurrent={setTest} handleExpandClick={handleExpandClick} current={test} hiddenSerialNos={hiddenSerialNos} handleHideSerialNos={handleHideSerialNos} handleSendEmail={handleSendEmail} handleCall={handleCall}>
                        <VerbTable headers={tableHeaders} body={contacts} handleContactChecked={handleContactChecked} selectedNumbers={selectedNumbers} hiddenSerialNos={hiddenSerialNos} />
                    </SuperContactsList>
                </div>
            </div>

        </>

    )
}

export default SuperContacts