import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";

import { Container, Row, Col } from "react-bootstrap";
import "./index.css";
import Sidebar from "../../../components/Sidebar";
import Navbar from "../../../components/Navbar";
import FacebookLoginButton from "../../Chats/facebookloginBtn";
import Customers from "./fileUpload/customers";
import { Route, Routes } from "react-router-dom";
import SFLogin from "./sfLogin/sfLogin";
import Landing from "./landing/landing";
import SFOps from "./sfOps/sfOps";
import { ToastContainer } from "react-toastify";

const CutomerHome = () => {
    const [isLoggedInSF, setIsLoggedInSF] = useState(false)

    return(
      <>
      <Routes>
        <Route index element={<Landing />}></Route>
        <Route path="file" element={<Customers />}></Route>
        {/*Conditionally make the path /app/calls/customers/salesforce as login screen or the salesforce operations page depending on whether the user is logged in or not to salesforce. */}
        <Route path="salesforce" element={isLoggedInSF?<SFOps setIsLoggedInSF={setIsLoggedInSF}/>:<SFLogin setIsLoggedInSF={setIsLoggedInSF}/>}></Route>
      </Routes>
      </>
    )
};

export default CutomerHome;
