import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./App.css";

import Dashboard from "./pages/Home";
import Signup from "./pages/auth/Signup";
import Login from "./pages/auth/login";
import UserInfo from "./pages/userInfo";
import CallIndex from "./pages/Chats/chatshome";
import Insights from "./pages/Calls/Insights";
import CustomerHome from "./pages/Calls/customers/index";
import Emailindex from "./pages/Emails";
import EmailInsights from "./pages/Emails/Insights";
import Stage from "./pages/staging/stage";
import CallsStaging from "./pages/Calls/staging/stage";
import ChatsStaging from "./pages/Chats/staging/stage";
import EmailsStaging from "./pages/Emails/staging/stage";
import ConfirmUser from "./pages/auth/confirmUser";
import ResetPassword from "./pages/auth/resetPassword";
import InsightContent from "./pages/Emails/Insights/InsightContent";
import NotFound from "./pages/notFound/notFound";
import ContactsStage from "./pages/contacts/staging/contactsStage";
import SuperContacts from "./pages/contacts/superContacts/superContacts";


/**
 * Routing can be confusing right ? More can be found on this in the README.
 */
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Signup />} />
        <Route path="signup" element={<Signup />} />
        <Route path="login" element={<Login />} />
        <Route path="/forgot_password" element={<ConfirmUser/>}></Route>
        <Route path="/reset_password" element={<ResetPassword />}></Route>
        <Route path='app/' element={<Stage />}>
          <Route path="" element={<CallsStaging />}>
            <Route path="" element={<Dashboard />}></Route>
          </Route>
          <Route path='contacts' element={<ContactsStage />}>
            <Route index element={<SuperContacts />}></Route>
            <Route path='home' element={<SuperContacts />}></Route>
          </Route>
          <Route path='calls' element={<CallsStaging />}>
            <Route index element={<Dashboard />}></Route>
            <Route path='home' element={<Dashboard />}></Route>
            <Route path='customers/*' element={<CustomerHome />}></Route>
            <Route path='insights' element={<Insights />}></Route>
          </Route>
          <Route path='chats' element={<ChatsStaging />}>
            <Route index element={<CallIndex />}></Route>
            <Route path='facebooklogin' element={<CallIndex />}></Route>
          </Route>
          <Route path='emails' element={<EmailsStaging />}>
            <Route index element={<Emailindex />}></Route>
            <Route path="home" element={<Emailindex />} />
            <Route path="insights" element={<InsightContent/>} />
          </Route>
        </Route>
        <Route path='*' element={<NotFound />}></Route>
      </Routes>
    </Router>
  )
}

export default App;
