// Dashboard.js
import React from "react";
import { redirect, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Route, Switch, Link, Routes } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import PieChartComponent from "../components/Piechart";
import Maincontent from "../components/maincontent";
import Navbar from "../components/Navbar";
import Col1 from "./Insights/Col1";
import DataTable from "../components/FetchedData";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import './Home.css'
import {Button,Form} from 'react-bootstrap'
import PhoneIcon from '@mui/icons-material/Phone';
import ContactsTable from "../components/Home/ContactsTable";
import apiURL from "../apiURL";
import { Sledding } from "@mui/icons-material";
import { handleAuthError } from "../utils/errorHandlers";

const FileSelector = (props) => {

  const [requestInProgress, setRequestInProgress] = useState(false)
  const [selectedFile,setSelectedFile] = useState("-")
  

  const handleCallClick = (e) => {
    if(selectedFile!="-"){
      setRequestInProgress(true)
      const endPoint = "/api/calls/connect_numbers_from_list_to_call_flow"

      const id = toast.loading("Sending request to place call.")
      //Replace caller_id and app_id harcoded here with the dyanmic caller_id and app_id.
      axios.post(apiURL+endPoint,{list_name:selectedFile,"caller_id" : "01140844551","app_id" : "730685"},{withCredentials:true})
        .then((res)=>{
          toast.update(id,{render : "Request for call placed successfully.", isLoading : false, type : "success", autoClose : 1000})
          setRequestInProgress(false)
        }).catch((err)=>{
          toast.update(id,{render : "Request for call unsuccessfull.", isLoading : false, type : "error", autoClose : 1000})
          setRequestInProgress(false)
          handleAuthError(err)
        })
    }
  }


  const handleSelectContacts = (e) =>{
    
    const endPoint = "/api/contacts/get_contacts_from_list"
    const id = toast.loading("Fetching contacts ...")
    axios.get(apiURL + endPoint + `/${selectedFile}`,{withCredentials:true})
      .then((response)=>{
        console.log(response.data)
        props.setContacts(response.data.data.contacts)
        toast.update(id,{render : "Setting up contacts ...",type : "success",isLoading : false, autoClose : 500})
      }).catch((err)=>{
        console.log(err)
        toast.update(id,{render:"Oops ! Some error occured ...", type : "error", isLoading : false, autoClose : 500})
        handleAuthError(err)
      })
  }

  const handleOnChange=(e)=>{
    props.setContacts([])
    props.setSelectedNumbers([])
    setSelectedFile(e.target.value)
  }

  const handleCall = (e) => {
    const id = toast.loading(`Placing request for ${props.selectedNumbers.length} ${props.selectedNumbers.length > 1 ? "calls" : "call"}`)
    const endPoint = "/api/calls/connect_numbers_to_call_flow"
    //Replace caller_id and app_id with actual ones
    //"caller_id" : "01140844551","app_id" : "730685"
    axios.post(apiURL + endPoint,{"caller_id" : "01140844551","app_id" : "730685",numbers:props.selectedNumbers},{withCredentials:true})
        .then((response)=>{
            toast.update(id,{render : "Call request successfull.",isLoading:false,type:"success",autoClose:500})
            const tempCallStatuses = {...props.callStatuses}
            for(const number of props.selectedNumbers) {
                tempCallStatuses[number] = true
            }
            console.log(response.data)
            props.setCallStatuses(tempCallStatuses)
        }).catch((err)=>{
            toast.update(id,{render : "Call request failed.",isLoading:false,type:"error",autoClose:500})
            handleAuthError(err)
        })
  }

  return (
    <>
    <div className="fileSelector">
      <label>Select file for upload : </label>
      <select name="file-selector-menu" onChange={(e) => handleOnChange(e)} disabled={requestInProgress}>
        <option>-</option>
          {props.files.map((val, id) => <option value={val} key={id}>{val}</option>)}
      </select>
      <Button style = {{background: "#A789EA", border: "1px solid white"}} disabled={requestInProgress ? requestInProgress : selectedFile === "-"} onClick={(e) => handleCallClick(e)}><PhoneIcon></PhoneIcon>{props.contacts.length===0?"Send file for calls":"Call all contacts"}</Button>
      <Button style = {{background: "#303279", border: "0px"}} disabled={requestInProgress ? requestInProgress : (selectedFile === "-" || props.contacts.length !=0 )} onClick={(e) => handleSelectContacts(e)}>Select contacts for calls</Button>
      {props.selectedNumbers.length === 0 ? null :  <Button onClick={(e)=>handleCall(e)}><PhoneIcon></PhoneIcon></Button>}
    </div>
    </>
  )
}


//Dashboard Start
const Dashboard = () => {
  const navigate = useNavigate()
  const [contacts, setContacts] = useState([])
  const [customer, setCustomer] = useState({})
  const [isAuth,setisAuth] = useState(false)
  const [files, setFiles] = useState([])
  const [selectedNumbers, setSelectedNumbers] = useState([])
  const [callStatuses, setCallStatuses] = useState({})
  // const domain = "http://localhost:6060"
  const api = "/api/customers/me"

  useEffect(()=>{

    axios
      .get(apiURL + api, { withCredentials: true })
      .then((res) => {
        if (res.data.success) {
          setisAuth(true);
          console.log("Success");
          setCustomer(res.data.data.user);
          console.log(customer);
          axios
            .get(apiURL+"/api/files/list_names", {
              withCredentials: true,
            })
            .then((res) => {
              setFiles(res.data.data.files.list_names);
              console.log(files);
            })
            .catch((err) => {
              console.log(err);
              handleAuthError(err)
            });
        }
      })
      .catch((err) => {
        console.log("ERROR");
        setCustomer({});
        setisAuth(false);
        navigate("/login");
      });

  },[])

  //Return this to isAuth after UI styling is done.
  if(isAuth){
    return (
      <div className="homeContent">
        <ToastContainer></ToastContainer>
      <div className="homeComponentContainer">
        <div className="homeSubComponentContainer1">
          <FileSelector files={files} setContacts={setContacts} contacts={contacts} selectedNumbers={selectedNumbers} setSelectedNumbers={setSelectedNumbers}callStatuses={callStatuses} setCallStatuses={setCallStatuses}/>
        </div>
        {contacts.length === 0 ? null :
        <div className="homeSubComponentContainer2">
            {contacts.length === 0 ? null : <ContactsTable contacts={contacts} setSelectedNumbers={setSelectedNumbers} selectedNumbers={selectedNumbers} callStatuses={callStatuses} setCallStatuses={setCallStatuses}/>}
        </div>
        }
      </div>
    </div>
    )
  } 
};

export default Dashboard;
